import { Button, ConfigProvider, Form, Input, Modal, Radio } from 'antd';
import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import { accountArray,findValueByKey} from './common';

const Withdrawal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashLINEID = queryParams.get('id');
  const [referrerCount,setReferrerCount] = useState(0);
  const [referrerSpCount,setReferrerSpCount] = useState(0);
  const [referrerFebCount,setReferrerFebCount] = useState(0);
  const [referrerFebCEOCount,setReferrerFebCEOCount] = useState(0);
  const [referrerStandardUserArray,setReferrerStandardUserArray] = useState([]);
  const [referrerPremiumUserArray,setReferrerPremiumUserArray] = useState([]);
  const [withdrawalDate,setWithdrawalDate] = useState(null);
  const [isModal,setIsModal] = useState(false);
  const [formValues, setFormValues] = useState({}); 
  const [pay,setPay] = useState(0);
  const [paidPlanPay,setPaidPlanPay] = useState(0);
  useEffect(()=>{
    if(hashLINEID === null){
      navigate('/Error');
    }
    init();
  },[]);

  const init = async() =>{
    if(await checkLINELogin()){
      if(await startLogin()){
        await getReferrerUserInfo();
        await getStandardReferrerUsers();
        await getPremiumReferrerUsers();
      }
      else{
        navigate('/Error');
      }
      
    }
    else{
      await startLINEAuth();
    }
  }

  useEffect(() =>{
    setPay(referrerCount*300 + referrerSpCount*500 + referrerFebCount*500 + referrerFebCEOCount*700 + referrerStandardUserArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0)*200 + referrerPremiumUserArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0)*400)
  },[referrerPremiumUserArray,referrerStandardUserArray])

  async function startLogin(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({hashLINEID}),
      });
  
      const data = await response.json();
      if(data){
        return data.isLogin;
      }
      else{
        return false;
      }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  }
  
  async function startLINEAuth(){
    const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
    const state = generateRandomString(8);
    const scope = 'profile openid';
    const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    window.location.href = authUrl;
  }

  function generateRandomString(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  async function checkLINELogin(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({accessOringn:"/Withdrawal?id=",parameter:hashLINEID}),
      });
  
      const data = await response.json();
      if(data){
        return data.isLogin;
      }
      else{
        return false;
      }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  }

  async function getReferrerUserInfo(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get/referrer', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({hashLINEID:hashLINEID}),
      });

      const data = await response.json();
      if(data){
        if(data.isError){
          navigate("/Error")
        }
        else{
          setReferrerSpCount(data.referrerSpCount);
          setReferrerCount(data.referrerCount);
          setReferrerFebCount(data.referrerFebCount);
          setReferrerFebCEOCount(data.referrerFebCEOCount);
          setWithdrawalDate(data.withdrawalDate);
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function getStandardReferrerUsers(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get/referrer/standard', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      if(data.isError){
        navigate("/Error")
      }
      else{
        console.log(`スタンダード:${data.referrerUserArray}`)
        setReferrerStandardUserArray(data.referrerUserArray);
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function getPremiumReferrerUsers(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get/referrer/premium', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      if(data.isError){
        navigate("/Error")
      }
      else{
        console.log(`プレミアム:${data.referrerUserArray}`)
        setReferrerPremiumUserArray(data.referrerUserArray);
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

const onSubmit = async(values) => {
    const postData = {
      hashLINEID: hashLINEID,
      referrerCount: referrerCount,
      referrerSpCount: referrerSpCount,
      referrerFebCount: referrerFebCount,
      referrerFebCEOCount: referrerFebCEOCount,
      paidPlanPay:paidPlanPay,
      withdrawalPay: pay,
      bank: values.bank,
      branch: values.branch,
      accountType: values.accountType,
      accountNumber: values.accountNumber,
      accountName: values.accountName,
      address: values.address,
      mail:values.mail
    }
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/withdrawal', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            navigate('/Error');
          }
          else{
            if(data.isEntry){
              navigate("/Error")
            }
            else{
              alert("出金依頼が完了しました。\nメールにて請求書が送信されていますので確認お願いいたします。");
              window.location.reload();
            }
          }
        }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  };
  const handleCancel = () => {
    setIsModal(false);
  }

  const onConfirm =(values)=>{
    if(pay < 2000){
      alert("出金可能額が2,000円以下のため出金できません");
    }
    else{
      setFormValues(values);
      setIsModal(true);
    }
  }
  

  return (
      <div className='main-content'>
        <div style={{margin:"0 40px"}}>
        <h2 className='page-title'>出金依頼</h2>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Input: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Radio:{
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Select: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Checkbox: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
              },
            }}
          >
          <div style={{color:"red",textAlign:"end",fontSize:"90%"}}>＊必須項目</div>
          <Form
            name="form"
            className="page-form"
            style={{margin:"20px auto"}}
            onFinish={onConfirm}
          >
          <div>【期間】{withdrawalDate && (new Date(withdrawalDate)).getFullYear() + "/" + ((new Date(withdrawalDate)).getMonth() + 1) + "/" + (new Date(withdrawalDate)).getDate()} ~ {(new Date()).getFullYear() + "/" + ((new Date()).getMonth() + 1) + "/" + (new Date()).getDate()}</div>
          <div>【期間内のユーザー招待数】{referrerCount+referrerSpCount+referrerFebCount+referrerFebCEOCount}人</div>
          {referrerPremiumUserArray.length + referrerStandardUserArray.length > 0 && <div>【招待した有料ユーザー数】{referrerPremiumUserArray.length + referrerStandardUserArray.length}人</div>}
          <div>【出金可能額】{(pay).toLocaleString()}円</div>
          <div>※最小出金可能額は2,000円です</div>
          <div>※振込手数料はお客様負担となります</div>
          <div>※出金依頼後、3日～1週間で入金いたします</div>
          <div>　</div>
          <div style={{textAlign:"end"}}><Link to={`/ReferrerUsersList?id=${hashLINEID}`} style={{color: '#07DFD8',textDecoration:"underline"}}>招待者一覧はこちら</Link></div>
            <div style={{margin:"40px 0"}}>
              <Form.Item
                name="bank"
                label={<span style={{fontSize:"120%"}}>銀行名</span>}
                rules={[{ required: true, message: '銀行名を入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="branch"
                label={<span style={{fontSize:"120%"}}>支店名</span>}
                rules={[{ required: true, message: '支店名を入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="accountType"
                label={<span style={{fontSize:"120%"}}>預金種類</span>}
                rules={[{ required: true, message: '預金種類を選択してください' }]}
                labelCol={{span:24}}
              >
                <Radio.Group name="isBranch">
                  {accountArray.map((item) => (
                    <Radio value={item.key}>{item.value}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              
              <Form.Item
                name="accountNumber"
                label={<span style={{fontSize:"120%"}}>口座番号</span>}
                rules={[{ required: true, message: '口座番号を入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="number"
                />
              </Form.Item>
              
              <Form.Item
                name="accountName"
                label={<span style={{fontSize:"120%"}}>名義カナ</span>}
                rules={[{ required: true, message: '名義カナを入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="address"
                label={<span style={{fontSize:"120%"}}>住所</span>}
                rules={[{ required: true, message: '住所を入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="mail"
                label={<span style={{fontSize:"120%"}}>請求書を受け取るメールアドレス</span>}
                rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
                labelCol={{span:24}}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item>

              <div style={{margin:"40px auto"}}>
                <div className="form-button">
                  <Button type="primary" htmlType="submit">
                    確認へ
                  </Button>
                </div>
              </div>
            </div>
              <Modal
                title="確認"
                open={isModal}
                onCancel={() => handleCancel()}
                footer={[
                  <>
                  {
                    <div style={{textAlign:"start"}}>
                    <div>下記の内容でお間違いないですか？</div>
                    <div>【出金金額】{(pay).toLocaleString()}円</div>
                    <div>【銀行名】{formValues.bank}</div>
                    <div>【支店名】{formValues.branch}</div>
                    <div>【預金種類】{findValueByKey(formValues.accountType,accountArray)}</div>
                    <div>【口座番号】{formValues.accountNumber}</div>
                    <div>【名義カナ】{formValues.accountName}</div>
                    <div>【住所】{formValues.address}</div>
                    <div>【請求書を受け取るメールアドレス】{formValues.mail}</div>
                    </div>
                  }
                      <Button key="cancel" onClick={() => handleCancel()}>
                        閉じる
                      </Button>
                      <Button type="primary" onClick={() => onSubmit(formValues)}>
                        出金依頼
                      </Button>
                  </>
                ]}
              >
              </Modal>
            </Form>
            
          </ConfigProvider>
        </div>
      </div>
  );
};

export default Withdrawal;
